<template>
  <div class="toothStylesExpression-Item">
    <mainMenu></mainMenu>
    <div class="toothStyleExpressionInfo" v-if="!isLoading">
      <div class="wrapper">
        <div class="toothStyleExpressionInfo-title">
          <router-link :to="{ name: 'toothStylesExpression' }" class="toothStyleExpressionInfo-backward">
            <icon name="chevron-left" scale="4"></icon>
          </router-link>
          <div class="toothStyleExpressionInfo-title-container">
            <h3>Expression Teeth Library</h3>
            <p>Фасон {{ currentStyle.title }}</p>
          </div>
        </div>
        <div class="toothStyleExpressionInfo-preview">
          <img :src="currentStyle.topBarImg" alt="" />
        </div>
      </div>
    </div>
    <div class="toothStyleExpressionContent" v-if="!isLoading">
      <div class="wrapper">
        <div class="toothStyleExpressionContent-faceImage">
          <img :src="currentStyle.faceImg" alt="" />
          <div class="toothStyleExpressionContent-actionBtn">
            <router-link
              :to="{ name: 'viewer3Dremote', params: { type: 'tooth_style_expression', set: currentStyle._id } }"
              class="btn-preview"
            >
              <img src="./../../assets/images/viewLink.png" alt="" /> Просмотреть
            </router-link>
            <a
              href="#"
              class="btn-download"
              v-if="!currentStyle.isNeedAccess"
              v-on:click.stop.prevent="isDownload = !isDownload"
            >
              <img src="./../../assets/images/downloadLink.png" alt="" />
              <span v-if="!isDownload">Скачать</span>
              <span v-else>Вернуться</span>
            </a>
            <router-link
              :to="{ name: isLoggedIn ? 'profile' : 'signin' }"
              class="btn-download"
              v-if="currentStyle.isNeedAccess"
            >
              <span>Для скачивания требуется премиум доступ!</span>
            </router-link>
          </div>
        </div>
        <div class="toothStyleExpressionContent-preview">
          <img :src="currentStyle.previewImg" alt="" v-if="!isDownload" />
          <div class="toothStyleExpressionDownloadList" v-if="isDownload">
            <a
              :href="currentStyle.download_pptx"
              v-if="currentStyle.download_pptx"
              class="toothStyleExpressionDownloadList-item pptx"
            >
              <img src="./../../assets/images/downloadIcons/pptx.png" class="downloadIcon" alt="" />
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать в</p>
                <span>PPTX</span>
              </div>
              <img src="./../../assets/images/downloadIcons/pptx_download_arrow.png" class="downloadArrow" alt="" />
            </a>
            <a
              :href="currentStyle.download_psd"
              v-if="currentStyle.download_psd"
              class="toothStyleExpressionDownloadList-item psd"
            >
              <img src="./../../assets/images/downloadIcons/psd.png" class="downloadIcon" alt="" />
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать в</p>
                <span>PSD</span>
              </div>
              <img src="./../../assets/images/downloadIcons/psd_download_arrow.png" class="downloadArrow" alt="" />
            </a>
            <a
              :href="currentStyle.download_stl"
              v-if="currentStyle.download_stl"
              class="toothStyleExpressionDownloadList-item stl"
            >
              <img src="./../../assets/images/downloadIcons/stl.png" class="downloadIcon" alt="" />
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать в</p>
                <span>STL</span>
              </div>
              <img src="./../../assets/images/downloadIcons/stl_download_arrow.png" class="downloadArrow" alt="" />
            </a>
            <a
              :href="currentStyle.download_exo_catback"
              v-if="currentStyle.download_exo_catback"
              class="toothStyleExpressionDownloadList-item exo"
            >
              <div><img src="./../../assets/images/downloadIcons/exo.png" class="downloadIcon" alt="" /></div>
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать Catback для</p>
                <span>EXO CAD</span>
              </div>
              <img src="./../../assets/images/downloadIcons/exo_download_arrow.png" class="downloadArrow" alt="" />
            </a>
            <a
              :href="currentStyle.download_exo"
              v-if="currentStyle.download_exo"
              class="toothStyleExpressionDownloadList-item exo"
            >
              <div><img src="./../../assets/images/downloadIcons/exo.png" class="downloadIcon" alt="" /></div>
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать для</p>
                <span>EXO CAD</span>
              </div>
              <img src="./../../assets/images/downloadIcons/exo_download_arrow.png" class="downloadArrow" alt="" />
            </a>
            <a
              :href="currentStyle.download_shape"
              v-if="currentStyle.download_shape"
              class="toothStyleExpressionDownloadList-item shape"
            >
              <div><img src="./../../assets/images/downloadIcons/3shape.png" class="downloadIcon" alt="" /></div>
              <div class="toothStyleExpressionDownloadList-item-title">
                <p>Скачать для</p>
                <span>3 Shape</span>
              </div>
              <img src="./../../assets/images/downloadIcons/3shape_download_arrow.png" class="downloadArrow" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <mainFooter></mainFooter>
  </div>
</template>

<script>
import mainMenu from "./../mainMenu/mainMenu.vue";
import mainFooter from "./../mainFooter/mainFooter.vue";

export default {
  name: "ToothStyleExpressionItem",
  components: { mainMenu, mainFooter },
  data() {
    return {
      isLoading: true,
      isDownload: false,
      endpoint: "tooth_styles_expression/" + this.$route.params.styleId,
      currentStyle: null,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
  methods: {
    getStyleItem() {
      this.isLoading = true;
      this.$http.get(this.endpoint).then((response) => {
        if (response.data.toothStyleExpression) {
          console.log(response.data.toothStyleExpression);
          this.currentStyle = response.data.toothStyleExpression;
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getStyleItem();
  },
};
</script>

<style lang="sass">
.toothStyleExpressionInfo
    display: flex
    background: #111
    border-bottom: 3px solid #8f8f8f
    padding: 0.75rem 1rem
    .wrapper
        flex-direction: row
        justify-content: space-between
        @media screen and (max-width: 768px)
            flex-direction: column
        .toothStyleExpressionInfo-title
            display: flex
            justify-content: center
            height: inherit
            .toothStyleExpressionInfo-backward
                display: flex
                justify-content: center
                align-items: center
                height: 100%
                color: #a3a0a0
                padding-right: 1rem
                transition: all 0.3s
                &:hover
                    color: #6c6c6c
            .toothStyleExpressionInfo-title-container
                display: flex
                flex-direction: column
                justify-content: center
                h3
                    color: #a3a0a0
                    font-size: 1.7rem
                    margin-bottom: 1rem
                    @media screen and (max-width: 768px)
                        text-align: center
                        font-size: 1.3rem
                p
                    display: block
                    color: #fff
                    font-size: 1.3rem
                    @media screen and (max-width: 768px)
                        text-align: center
                        font-size: 1rem

        .toothStyleExpressionInfo-preview
            display: flex
            align-items: center
            justify-content: center
            height: inherit
            img
                display: block
                height: 104px
                @media screen and (max-width: 768px)
                    object-fit: contain
                    height: 60px
                    width: 100%

.toothStyleExpressionContent
    display: flex
    background: #000
    min-height: calc(100vh - 190px)
    padding: 0 1rem 2rem
    .wrapper
        flex-direction: row
        justify-content: space-between
        @media screen and (max-width: 980px)
            flex-direction: column
        .toothStyleExpressionContent-faceImage
            max-width: 540px
            @media screen and (max-width: 980px)
                margin: 0 auto
            img
                display: block
                width: 100%
                height: auto
            .toothStyleExpressionContent-actionBtn
                display: flex
                justify-content: space-between
                margin-top: 2rem
                @media screen and (max-width: 425px)
                    flex-direction: column
                a
                    display: flex
                    align-items: center
                    justify-content: center
                    color: #fff
                    width: calc(50% - 1rem)
                    padding: 0.5rem 0.5rem
                    text-align: center
                    transition: all 0.3s
                    @media screen and (max-width: 425px)
                        width: 100%
                        &:first-child
                            margin-bottom: 0.5rem
                    img
                        display: block
                        width: 31px
                        height: 31px
                        margin-right: 0.5rem
                    &.btn-download
                        background: #ae2877
                        &:hover
                            background: #ce027a
                    &.btn-preview
                        background: #4e8a27
                        &:hover
                            background: #50b50f

        .toothStyleExpressionContent-preview
            @media screen and (max-width: 980px)
                margin: 0 auto
            img
                display: block
                width: 100%
                height: auto
        .toothStyleExpressionDownloadList
            display: flex
            flex-direction: column
            .toothStyleExpressionDownloadList-item
                display: flex
                justify-content: space-between
                min-width: 320px
                padding: 0.5rem 0.5rem
                margin-top: 0.5rem
                border: 1px solid transparent
                border-radius: 6px
                align-items: center
                transition: all 0.3s
                .downloadIcon
                    display: block
                    height: 100%
                    width: auto
                .downloadArrow
                    display: block
                    height: 100%
                    width: auto
                    opacity: 0
                    transition: all 0.3s
                &:hover
                    .downloadArrow
                        opacity: 1
                &.pptx
                    color: #ef5398
                    border-color: #ef5398
                &.psd
                    color: #6bc5cf
                    border-color: #6bc5cf
                &.stl
                    color: #ffde16
                    border-color: #ffde16
                &.exo
                    color: #ae72ac
                    border-color: #ae72ac
                &.shape
                    color: #c6fe88
                    border-color: #c6fe88
                .toothStyleExpressionDownloadList-item-title
                    text-align: center
                    font-weight: bold
                    p
                        font-size: 1.3rem
                    span
                        font-size: 1.2rem
</style>
